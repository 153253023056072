/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import flower1 from "../../assets/flower1.JPG";
import flower2 from "../../assets/flower2.JPG";
import flower3 from "../../assets/flower3.JPG";
import flower4 from "../../assets/flower4.JPG";
import flower5 from "../../assets/flower5.JPG";
import flowerVista1 from "../../assets/flowerVista1.JPG";

const Home = () => {
  return (
    <div>
      <section>
        <div className="">
          <div className="container mx-auto flex flex-col items-center px-4 py-16 pb-16 text-center lg:pb-24 md:py-32 md:px-10 lg:px-32 dark:text-coolGray-900">
            <h1 className="text-5xl font-light leading-none sm:text-8xl xl:max-w-3xl text-vhGreen">
              <em>Vreugdenhil Farm</em>
            </h1>
            <p className="mt-6 mb-8 text-2xl sm:mb-12 xl:max-w-3xl text-vhGreen font-light">
              <em>Seasonal Specialty Cut Flowers</em>
            </p>
            <div className="flex flex-wrap justify-center"></div>
          </div>
        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="flex flex-col items-end px-3">
            <img
              className="object-cover mb-6 rounded shadow-xl h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56 transition duration-700 ease-in-out transform hover:scale-105"
              src={flower1}
              alt=""
            />
            <img
              className="object-cover w-20 h-20 rounded shadow-xl sm:h-32 xl:h-40 sm:w-32 xl:w-40 transition duration-700 ease-in-out transform hover:scale-105"
              src={flower2}
              alt=""
            />
          </div>
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-xl sm:h-64 xl:h-80 sm:w-64 xl:w-80 transition duration-700 ease-in-out transform hover:scale-105"
              src={flower3}
              alt=""
            />
          </div>
        </div>
        <div className="flex items-center justify-center -mx-4 lg:pl-8">
          <div className="px-3">
            <img
              className="object-cover w-40 h-40 rounded shadow-xl sm:h-64 xl:h-80 sm:w-64 xl:w-80 transition duration-700 ease-in-out transform hover:scale-105"
              src={flowerVista1}
              alt=""
            />
          </div>
          <div className="flex flex-col items-start px-3">
            <img
              className="object-cover mb-6 w-20 h-20 rounded shadow-xl sm:h-32 xl:h-40 sm:w-32 xl:w-40 transition duration-700 ease-in-out transform hover:scale-105"
              src={flower4}
              alt=""
            />
            <img
              className="object-cover rounded shadow-xl h-28 sm:h-48 xl:h-56 w-28 sm:w-48 xl:w-56 transition duration-700 ease-in-out transform hover:scale-105"
              src={flower5}
              alt=""
            />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
