import React from "react";

const Loading = () => {
  return (
    <>
      <div className="h-1/2 flex items-center justify-center space-x-2">
        <h3 className="block text-light text-vhGreen text-2xl animate-pulse">
          <em>Loading</em>
        </h3>
      </div>
    </>
  );
};

export default Loading;
