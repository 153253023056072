/* eslint-disable jsx-a11y/anchor-is-valid */
/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { MenuIcon, XCircleIcon } from "@heroicons/react/outline";
import { useRecoilValue } from "recoil";
import { currentUserState } from "../../recoil/atoms/currentUserState";
import vhfarmLogo from "../../assets/vhfarmLogo.svg";

const Header = () => {
  const currentUser = useRecoilValue(currentUserState);

  return (
    <>
      <Disclosure as="nav" className="bg-white">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XCircleIcon
                        className="block h-6 w-6 text-vhGreen"
                        aria-hidden="true"
                      />
                    ) : (
                      <MenuIcon
                        className="block h-6 w-6 text-vhGreen"
                        aria-hidden="true"
                      />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <Link to="/" className="flex-shrink-0 flex items-center">
                    <img
                      className="hidden lg:block h-14 w-auto"
                      src={vhfarmLogo}
                      alt="Workflow"
                    />
                  </Link>
                  <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                    {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                    <Link
                      to="/"
                      className="text-vhGreen hover:text-gray-700 inline-flex items-center px-1 pt-1 text-xl font-light"
                    >
                      Home
                    </Link>
                    {currentUser.uid && (
                      <Link
                        to="/about"
                        className="text-vhGreen hover:text-gray-700 inline-flex items-center px-1 pt-1 text-xl font-light"
                      >
                        About
                      </Link>
                    )}
                    <Link
                      to="/gallery"
                      className=" text-vhGreen hover:text-gray-700 inline-flex items-center px-1 pt-1 text-xl font-light"
                    >
                      Gallery
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <Disclosure.Panel className="sm:hidden">
              <div className="pt-2 pb-4 space-y-1 absolute shadow-lg rounded-md bg-gray-100 z-50">
                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                <Disclosure.Button
                  as={NavLink}
                  to="/"
                  className="text-vhGreen block pl-3 pr-4 py-2 text-base font-light"
                >
                  Home
                </Disclosure.Button>
                {currentUser.uid && (
                  <Disclosure.Button
                    as={NavLink}
                    to="/about"
                    className="text-vhGreen block pl-3 pr-4 py-2 text-base font-light"
                  >
                    About
                  </Disclosure.Button>
                )}
                <Disclosure.Button
                  as={NavLink}
                  to="/gallery"
                  className="text-vhGreen block pl-3 pr-4 py-2 text-base font-light"
                >
                  Gallery
                </Disclosure.Button>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default Header;
