import React, { useEffect, useState } from "react";
import Loading from "../../layout/Loading";
import { imageCollection } from "../../../utils/firebase/firebase";
import { firebaseLooper } from "../../../utils/firebase/tools";

const Gallery = () => {
  const [itemsList, setItemsList] = useState(null);

  useEffect(() => {
    imageCollection
      .orderBy("index")
      .get()
      .then((snapshot) => {
        const items = firebaseLooper(snapshot);
        setItemsList(items);
      });
  }, []);

  return (
    <>
      <div className="">
        <div className="container mx-auto flex flex-col items-center px-4 py-16 pb-16 text-center lg:pb-24 md:py-32 md:px-10 lg:px-32 dark:text-coolGray-900">
          <h1 className="text-5xl font-light leading-none sm:text-4xl xl:max-w-3xl text-vhGreen">
            <em>Gallery</em>
          </h1>
        </div>
      </div>
      <hr className="w-3/4 m-auto sm:w-1/2" />
      {itemsList ? (
        <section className="py-6 dark:bg-coolGray-800 dark:text-coolGray-50">
          <div className="container mx-auto grid grid-cols-2 gap-4 p-4 md:grid-cols-3 md:w-2/3">
            {itemsList.map((item) => {
              return (
                <a key={item.index} href={item.fullSizeImage} target="_new">
                  <img
                    src={item.fullSizeImage}
                    alt=""
                    className="transition duration-700 ease-in-out transform hover:scale-105 w-full h-full object-cover rounded-lg shadow-xl min-h-48"
                  />
                </a>
              );
            })}
          </div>
        </section>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Gallery;
