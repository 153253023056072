import React, { Fragment, useState } from "react";
import { useRecoilState } from "recoil";
import { loginModalState } from "../../../recoil/atoms/loginModalState";
import { Dialog, Transition } from "@headlessui/react";
import firebase, { userCollection } from "../../../utils/firebase/firebase";
import { XCircleIcon } from "@heroicons/react/outline";
import "./login.css";
import LoginForm from "./LoginForm";

const LoginDrawer = () => {
  const [showModal, setShowModal] = useRecoilState(loginModalState);

  const [authForm, setAuthForm] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });

  const [isNewUser, setIsNewUser] = useState(false);

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      handleSubmit(e);
    }
  };

  const resetForm = () => {
    setAuthForm({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
    });
  };

  const handleNewSwitch = () => {
    setIsNewUser(!isNewUser);
  };

  const handleChange = (e) => {
    setAuthForm({
      ...authForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isNewUser) {
      firebase
        .auth()
        .createUserWithEmailAndPassword(authForm.email, authForm.password)
        .then((res) => {
          saveNewUser(res);
          resetForm();
          setShowModal({ open: false });
        })
        .catch((err) => {
          resetForm();
          if (err.code === "auth/email-already-in-use") {
            alert("An account exists with that email.");
          }
        });
    } else {
      firebase
        .auth()
        .signInWithEmailAndPassword(authForm.email, authForm.password)
        .then((res) => {
          resetForm();
          setShowModal({ open: false });
        })
        .catch((err) => {
          resetForm();
          if (err.code === "auth/user-not-found") {
            alert("There is no user record corresponding to this identifier.");
          }
        });
    }
  };

  const saveNewUser = (data) => {
    userCollection
      .doc(data.user.uid)
      .set({
        email: data.user.email,
        firstName: authForm.firstName,
        lastName: authForm.lastName,
        isAdmin: false,
      })
      .then((res) => {})
      .catch((err) => {});
  };
  return (
    <Transition.Root show={showModal.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={() => setShowModal({ open: false })}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-80 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="h-full flex flex-col py-6 bg-white overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <Dialog.Title className="text-2xl font-light text-vhGreen">
                        Welcome!
                      </Dialog.Title>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-vhGreen hover:text-gray-500 focus:outline-none"
                          onClick={() => setShowModal({ open: false })}
                        >
                          <span className="sr-only">Close panel</span>
                          <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    {/* Replace with your content */}
                    <LoginForm
                      isNewUser={isNewUser}
                      authForm={authForm}
                      handleChange={handleChange}
                      handleSubmit={handleSubmit}
                      handleNewSwitch={handleNewSwitch}
                      onKeyDown={onKeyDown}
                    />
                    {/* /End replace */}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default LoginDrawer;
