import React from "react";

const LoginForm = ({
  isNewUser,
  authForm,
  handleChange,
  handleSubmit,
  onKeyDown,
  handleNewSwitch,
}) => {
  return (
    <>
      <div className="absolute inset-0 px-4 sm:px-6">
        <div className="relative p-6 flex-auto bg-white">
          <h4 className="text-vhGreen font-light">
            Sign in to your admin account.
          </h4>
          <form
            autoComplete="off"
            type="submit"
            onSubmit={(e) => handleSubmit(e)}
            className="bg-white"
          >
            <div className="my-4 text-sm">
              <label className="block text-vhGreen">Email</label>
              <input
                value={authForm.email || ""}
                onChange={(e) => handleChange(e)}
                type="text"
                name="email"
                placeholder="Email"
                className="w-full px-4 py-3 rounded-md"
              />
            </div>
            <div className="my-4 text-sm">
              <label className="block text-vhGreen">Password</label>
              <input
                value={authForm.password || ""}
                onChange={(e) => handleChange(e)}
                type="password"
                name="password"
                placeholder="Password"
                className="w-full px-4 py-3 rounded-md"
                onKeyDown={(e) => onKeyDown(e)}
              />
            </div>
          </form>
        </div>
        {/*footer*/}
        <div className="flex items-center justify-center p-6 border-t border-solid border-blueGray-200 rounded-b">
          <button
            className="text-vhGreen background-transparent font-light uppercase px-6 py-2 text-xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
            type="button"
            onClick={(e) => handleSubmit(e)}
          >
            {isNewUser ? "Sign Up" : "Login"}
          </button>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
