/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSetRecoilState } from "recoil";
import { currentUserState } from "../recoil/atoms/currentUserState";
import Routes from "../routes";
import firebase from "../utils/firebase/firebase";

function App() {
  const setCurrentUser = useSetRecoilState(currentUserState);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setCurrentUser({
          email: user.email,
          uid: user.uid,
        });
      } else {
        setCurrentUser({
          email: null,
          uid: null,
        });
      }
    });
  }, []);

  return (
    <div className="mx-auto">
      <Routes />
    </div>
  );
}

export default App;
