import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCJ64p0PXoM10IMuMBzQYQJZSmtwsV2Pyc",
  authDomain: "vreugdenhilfarm-tvb.firebaseapp.com",
  projectId: "vreugdenhilfarm-tvb",
  storageBucket: "vreugdenhilfarm-tvb.appspot.com",
  messagingSenderId: "155237990055",
  appId: "1:155237990055:web:d987aef63bf334bdf76582",
  measurementId: "G-1L7BCMZ89B",
};

firebase.initializeApp(firebaseConfig);

// Firestore setup
const db = firebase.firestore();
export const firebaseTimestamp = firebase.firestore.FieldValue.serverTimestamp;
export const storage = firebase.storage();
export const storageRef = storage.ref();

// Collections
export const userCollection = db.collection("users");
export const imageCollection = db.collection("images");

//Storage Refs
export const imageRef = storageRef.child("images");
export const flowersFullRef = imageRef.child("flowersFull");
export const flowersThumbRef = imageRef.child("flowersThumb");

export default firebase;
