import React, { Suspense } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollToTop from "./components/layout/helpers/ScrollToTop";
import Footer from "./components/layout/Footer";

// Components
import Header from "./components/layout/Header";
import Home from "./components/pages/Home";
import Gallery from "./components/pages/gallery/Gallery";
import LoginDrawer from "./components/pages/login/LoginDrawer";
import Loading from "./components/layout/Loading";
import About from "./components/pages/about/About";

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <LoginDrawer />
      <Header />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/gallery" component={Gallery} />
          <Route exact path="/about" component={About} />
        </Switch>
      </Suspense>
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
